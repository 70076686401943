/* stylelint-disable custom-property-pattern */
@import "./mixin";
@import "@fontsource/montserrat";
@import "@fontsource/montserrat/400.css";
@import "@fontsource/montserrat/400-italic.css";

:root {
  --global-background-color: #fff;
  --icon-background-color: #000;
  --doc-ico-bg: #00adb51a;
  --box-fill-color: radial-gradient(ellipse farthest-corner at center center, #104c66 0%, #25aae1 70%);

  // --gradient-top: #1A93FC;
  // --gradient-bottom:#094A9C;
  --gradient-top: rgba(26, 147, 252, 0.58);
  --gradient-bottom: rgba(9, 74, 156, 0.59);
  --loginBox: #ffffffda;
  --text-decoration-color: #000;
  --header-text-color: #000;
  --tab-background-color1: #25aae1;
  --tab-background-color2: #faad14;
  --display-text-color: #000;
  --text-background-color: #fff;
  --footer-text-color: #fff;

  // --primary: rgb(131, 89, 143)
  --primary: #25aae1;
  --primary-dark: #1384B0;

  // --primary: #040104;

  --primary-alt: #83598f;
  --light-blue: #c5ccf6;
  --lighter-blue: #e9ecff;
  --dark-blue: #271b50;
  --portal-primary: #40a9ff;
  --portal-secondary: #faad14;
  --blue: #081a5c;
  --loginBG: #cecece;

  // --secondary: #835990;
  --secondary: #faad14;

  // --secondary: #ff9a25;
  --secondary-alt: #442c4a;
  --secondary-hover: #faad14;
  --footer-dark: #271b50;
  --black: #000;
  --white: #fff;
  --unread: #d6ebff;
  --gray: #e0dfdc;
  --white-smoke: #f5f5f5;
  --dark-gray: #8d8f94;
  --success: #1a8d08;
  --green: #73cb57;
  --purple: #7e57c2;
  --scroll-border: #e3e3e3;
  --light-gray: #f2f2f2;
  --default-box-shadow: 0 2px 15px 0 #dcdcdc;
  --gradient-background: linear-gradient(to right, #f3b605 0%, #ff6b5e 100%);
  --padding-desktop: 60px 0;
  --padding-desktop-large: 90px 0;
  --border-color: #dadce0;
  --alice-blue: #f5fcff;
  --very-light-gray: #f9f9f9;
  --light-shade-gray: #ddd;
  --alice-light-blue: #f4f7f9;
  --caribbean-green: #026ec1;
  --dark-charcoal: #333;
  --chinese-silver: #ccc;
  --oswaldFont: "Oswald";
}

@mixin card-bg {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background: var(--white-smoke);
  background: hsla(0deg, 0%, 100%, 0.98);
  border: 1px solid #efefef;
  -webkit-box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.01%), 0 -2px 16px 0 rgba(0, 0, 0, 0.05%);
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0%), 0 -2px 16px 0 rgba(0, 0, 0, 0.05%);
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

// webkit scrollbar starts

body::-webkit-scrollbar-track,
.easypraxis-dashboard ::-webkit-scrollbar-track,
.role-management::-webkit-scrollbar-track,
.ant-table-content::-webkit-scrollbar-track,
.workflow.app-sidebar .workflow-container::-webkit-scrollbar-track,
.workflow-view-container::-webkit-scrollbar-track,
.ant-modal-wrap::-webkit-scrollbar-track,
.main-content-container::-webkit-scrollbar-track,
.main-sider::-webkit-scrollbar-track,
.right-field-details::-webkit-scrollbar-track {
  // background-color: var(--white-smoke);
  border-radius: 10px;
}

body::-webkit-scrollbar,
.easypraxis-dashboard ::-webkit-scrollbar,
.role-management::-webkit-scrollbar,
.ant-table-content::-webkit-scrollbar,
.workflow.app-sidebar .workflow-container::-webkit-scrollbar,
.workflow-view-container::-webkit-scrollbar,
.ant-modal-wrap::-webkit-scrollbar,
.main-content-container::-webkit-scrollbar,
.main-sider::-webkit-scrollbar,
.right-field-details::-webkit-scrollbar {
  // background-color: var(--white-smoke);
  height: 3px;
  width: 3px;
}

body::-webkit-scrollbar-thumb,
.easypraxis-dashboard ::-webkit-scrollbar-thumb,
.role-management::-webkit-scrollbar-thumb,
.ant-table-content::-webkit-scrollbar-thumb,
.workflow.app-sidebar .workflow-container::-webkit-scrollbar-thumb,
.workflow-view-container::-webkit-scrollbar-thumb,
.ant-modal-wrap::-webkit-scrollbar-thumb,
.main-content-container::-webkit-scrollbar-thumb,
.main-sider::-webkit-scrollbar-thumb,
.right-field-details::-webkit-scrollbar-thumb {
  background-color: var(--scroll-border);
  border-radius: 10px;
}

// webkit scrollbar ends

body {
  // background-color: var(--primary);
  background-color: var(--global-background-color);
  color: var(--display-text-color);

  // font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
  font-family: Calibri;
  // font-family: Montserrat;
  font-size: 15px;
  overflow-x: hidden;
}

a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--header-text-color);
  // font-family: Montserrat;
  font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
  font-weight: 500;
}

h3 {
  font-size: 1rem; //if font family is calibri..
}

a {
  color: var(--primary);
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

hr {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #b6b6b6;
  box-sizing: content-box;
  height: 0;
  margin: 16px 0;
  overflow: visible;
}

.container {
  margin: 0 auto;
  max-width: 1140px;
  padding-left: 15px;
  padding-right: 15px;
  width: 85%;
}

.border-top {
  border-top: 1px solid var(--chinese-silver);
}

.box-shadow {
  box-shadow: var(--default-box-shadow);
}

.ant-layout-header {
  -webkit-box-shadow: 0 1px 5px 0 rgba(41, 85, 115, 0.21);
  -moz-box-shadow: 0 1px 5px 0 rgba(41, 85, 115, 0.21);
  box-shadow: 0 1px 5px 0 rgba(41, 85, 115, 0.21);
  z-index: 9;
}

.ant-layout-header.fixed-header {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.default-spacing {
  margin-top: 54px;
}

button {
  border-radius: 5px;
}

h2 {
  font-size: 21px;
}

.margin-top20 {
  margin-top: 20px;
}

.margin-bottom20 {
  margin-bottom: 20px;
}

a.standard,
button,
button.primary,
.ant-btn-primary,
.ant-btn-standard,
.ant-btn-secondary,
.ant-btn-button.secondary {
  border-radius: 3px;
  box-shadow: none;
  font-size: 14px;
  font-weight: 400;
  height: 32px;
  outline: none;
  text-shadow: none;
  width: auto;
}

.ant-btn-secondary:hover {
  background-color: var(--secondary) !important;
  color: var(--black) !important;
}

a.standard,
.ant-btn-primary,
.ant-btn-secondary {
  // box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
  color: var(--white);
  font-weight: 500;
}

.ant-btn:hover {
  color: var(--white) !important;
}

a.standard,
.ant-btn-primary,
.btn-glow.primary {
  background: var(--primary);
  border: transparent;
  border-radius: 20px;
  color: var(--white);

  &:hover {
    color: var(--black);
  }
}

.ant-btn-secondary {
  background-color: var(--portal-secondary);
  border-radius: 20px;
  color: black;

  // &:hover {
  //   background-color: var(--portal-primary);
  //   color: var(--black);
  // }
}

// .ant-btn-primary:hover,
// .btn-glow.primary:hover,
// .ant-btn-primary:focus {
//   background: var(--primary-alt);
//   border-color: var(--primary-alt);
// }

.ant-btn-secondary,
.btn-primary {
  // background: var(--secondary);
  // border: 1px solid var(--secondary);
  color: var(--white);
}

.ant-btn-button.secondary {
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
  color: var(--white);
}

.btn-white {
  background: var(--white);
  border: 1px solid var(--white);
  color: var(--primary);
}

.btn-coloured {
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
  color: var(--white);
  display: inline-block;
  text-align: center;
}

.btn-coloured:hover {
  background-color: var(--secondary-hover);
  color: var(--white);
}

.btn-large {
  border-radius: 3px;
  font-size: 16px;
  padding: 12px 31px;

  @media (max-width: 360px) {
    width: 100%;
  }
}

.btn-right-space {
  margin-right: 20px;

  @media (max-width: 600px) {
    margin-right: 0;
  }
}

.btn-border-white {
  background-color: transparent;
  border: 1px solid var(--white);
  color: var(--white);
}

// container-fluid starts

.container-fluid {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

// container-fluid ends

// input fields starts
.ant-input:focus,
.ant-input-focused,
.ant-picker-focused,
.ant-picker-focused,
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  @include antInputFocus;
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="date"],
.ant-picker,
select {
  border-radius: 3px;
  font-size: 14px;
  height: 35px;
  outline: none;
  padding: 6px 8px;
  position: relative;
  width: 100%;
}

.ant-input {
  border-radius: 3px;
}

input[type="text"],
input[type="text"]:hover,
input[type="text"]:focus {
  outline: none;
}

.ant-input-affix-wrapper {
  border-radius: 3px;
  height: 35px;
}

.ant-select-multiple .ant-select-selector {
  height: auto !important;
}

.ant-select-selection-item {
  color: #262626;
}

.ant-select-selector {
  border-radius: 3px !important;
  height: 35px !important;
}

.ant-select.select-after.ant-select-single.ant-select-show-search {
  min-width: 40px !important;
}

.ant-input-affix-wrapper input[type="text"],
.ant-input-affix-wrapper input[type="password"] {
  height: auto;
}

.ant-checkbox-wrapper {
  font-size: 14px;
}

// input fields ends here

.ant-layout {
  background: var(--white);
}

// header changes

.ant-layout-header {
  // background: linear-gradient(268.79deg, var(--primary-alt) 0%, var(--primary) 65%,var(--primary) 86%);

  background: var(--white);
  border-radius: 0;
  color: var(--dark-charcoal);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#2c3742", endColorstr="#28303a", GradientType=0);
  height: 54px;
  line-height: 52px;
  margin-bottom: 0;
  min-height: 54px;
  padding: 0 12px 0 0 !important;
}

// headder changes ends

// font size starts

.ant-tabs-tab,
.ant-tabs {
  font-size: 14px;
}

// font size ends

// Top menu section after login

.left-menu {
  float: left;
  width: 75%;

  .main-title {
    width: 100%;

    h1 {
      font-weight: 500;
      margin-bottom: 0;
    }
  }

  .search {
    margin-top: 10px;
    padding-right: 10px;
    width: 100%;
  }
}

.left-menu .left-menu .main-title .left-menu .action-icons {
  width: 100%;

  ul {
    display: inline-block;
    list-style: none;
    margin-bottom: 0;
    padding: 0 10px 0 0;
    width: 100%;

    li {
      display: inline-block;
      padding: 0 10px;

      a {
        line-height: 64px;
        padding: 6px;

        &.add-new {
          border: 1px solid #fbfbfb;
          border-radius: 5px;
          padding: 5px 20px;
        }
      }

      button {
        i {
          margin-right: 7px;
        }
      }
    }
  }
}

// Top menu section after login ends

// inner pages css starts
.breadcrum-title.without-bg {
  background-image: unset;
}

.breadcrum-title.only-heading {
  background-position: 89% 100%;
  background-repeat: no-repeat;
  background-size: 51%;

  @media (max-width: 600px) {
    background-size: 80%;
  }
}

.breadcrum-title {
  background-color: #f5f5f5;
  background-position: 10% 100%;
  background-repeat: no-repeat;
  background-size: 72%;
  float: left;
  overflow: hidden;
  padding-top: 40px;
  position: relative;
  width: 100%;

  h1 {
    color: var(--black);
    font-size: 30px;
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 15px;

    @media (max-width: 900px) {
      font-size: 26px;
    }
  }

  img {
    display: block;
    height: 260px;
    margin: 0 auto;

    @media (max-width: 600px) {
      width: 95%;
    }
  }

  p {
    color: #575757;
    font-size: 19px;
  }

  .breadcrum-content {
    padding-bottom: 33px;

    @media (max-width: 780px) {
      padding-bottom: 20px;
    }
  }

  @media (max-width: 900px) {
    padding: 30px 0;
  }
}

.breadcrum-title.text-center {
  text-align: center;

  h1 {
    font-size: 37px;
    text-align: center;
  }
}

// order-breadcrumb

.order-breadcrumb {
  background: #f5f5f5;
  padding: 20px 0;
}

.inner-pages {
  background-color: var(--white);
  float: left;
  padding: 60px 0;
  width: 100%;

  p,
  ul {
    font-size: 16px;
    line-height: 181%;
  }

  h3 {
    font-size: 22px;
  }
}

.full-width-section {
  display: block;
  float: left;
  width: 100%;
}

// inner pages css ends

.inner-pages.about-us h1 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
}

.inner-pages.about-us p {
  color: #000;
  font-size: 17px;
  line-height: 180%;
}

// footer starts

.ant-layout-footer {
  background: var(--white);
  color: var(--white);
  padding: 16px 50px;
  z-index: 1;

  p {
    @media (max-width: 900px) {
      margin-bottom: 10px;
    }
  }

  p,
  ul li a {
    color: var(--white);
  }

  @media (max-width: 900px) {
    margin-top: 30px;
    padding: 30px 20px;
    text-align: center;
  }
}

.inner-contents {
  padding: 30px 0;

  .custom-background {
    padding: 0;
  }
}

.inner-contents .save-changes,
.fixed-action-area {
  @include card-bg;

  bottom: 0;
  left: 0;
  padding: 10px;
  position: fixed;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.inner-contents .save-changes,
.fixed-action-area {
  z-index: 99;
}

.app-sidebar {
  position: relative;
}

// default input fields height

.filter-section .ant-select:not(.ant-select-auto-complete) .ant-select-selector,
.filter-section .ant-picker,
.filter-section .ant-input-affix-wrapper,
.filter-section .custom-input-box input {
  -webkit-appearance: none;
  background: transparent;
  background-color: var(--white);
  border: 1px solid var(--chinese-silver);
  border-radius: 3px !important;
  color: #737f8d;
  font-size: 12px;
  height: 29px !important;
  line-height: inherit;
  padding: 1px 7px 0;
  vertical-align: middle;
}

// default input fields height ends

.ant-select-multiple .ant-select-selector {
  height: auto !important;
}

.status.badge {
  border-radius: 10px;
  -webkit-box-shadow: -1px 1px 5px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 5px -2px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 1px 5px -2px rgba(0, 0, 0, 0.75);
  color: var(--white);
  cursor: pointer;
  display: block;
  font-size: 82%;
  min-width: 100px;
  padding: 2px 10px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
}

.status.badge {

  &.pending,
  &.created {
    background: #ff9800;
    background: -moz-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #ffb64a), color-stop(100%, #ff9800));
    background: -webkit-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
    background: -o-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
    background: -ms-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
    background: linear-gradient(to bottom, #ffb64a 0%, #ff9800 100%);
  }
}

.status.badge.issued {
  background: #f85032;
  background: -moz-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 51%, #f02f17 71%, #e73827 100%);
  background:
    -webkit-gradient(left top,
      left bottom,
      color-stop(0%, #f85032),
      color-stop(0%, #f16f5c),
      color-stop(51%, #f6290c),
      color-stop(71%, #f02f17),
      color-stop(100%, #e73827));
  background: -webkit-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 51%, #f02f17 71%, #e73827 100%);
  background: -o-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 51%, #f02f17 71%, #e73827 100%);
  background: -ms-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 51%, #f02f17 71%, #e73827 100%);
  background: linear-gradient(to bottom, #f85032 0%, #f16f5c 0%, #f6290c 51%, #f02f17 71%, #e73827 100%);
}

.status.badge.sent {
  background: #ffc107;
  background: -moz-linear-gradient(top, #ffc107 0%, #ebb30b 0%, #d5a411 51%, #c29716 71%, #b28b19 100%);
  background:
    -webkit-gradient(left top,
      left bottom,
      color-stop(0%, #ffc107),
      color-stop(0%, #ebb30b),
      color-stop(51%, #d5a411),
      color-stop(71%, #c29716),
      color-stop(100%, #b28b19));
  background: -webkit-linear-gradient(top, #ffc107 0%, #ebb30b 0%, #d5a411 51%, #c29716 71%, #b28b19 100%);
  background: -o-linear-gradient(top, #ffc107 0%, #ebb30b 0%, #d5a411 51%, #c29716 71%, #b28b19 100%);
  background: -ms-linear-gradient(top, #ffc107 0%, #ebb30b 0%, #d5a411 51%, #c29716 71%, #b28b19 100%);
  background: linear-gradient(to bottom, #ffc107 0%, #ebb30b 0%, #d5a411 51%, #c29716 71%, #b28b19 100%);
}

.status.badge.partiallypaid {
  background: #f85032;
  background: -moz-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 50%, #82bc40 50%, #47761d 100%);
  background:
    -webkit-gradient(left top,
      left bottom,
      color-stop(0%, #f85032),
      color-stop(0%, #f16f5c),
      color-stop(50%, #f6290c),
      color-stop(71%, #82bc40),
      color-stop(100%, #47761d));
  background: -webkit-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 50%, #82bc40 50%, #47761d 100%);
  background: -o-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 50%, #82bc40 50%, #47761d 100%);
  background: -ms-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 50%, #82bc40 50%, #47761d 100%);
  background: linear-gradient(to right, #f85032 0%, #f16f5c 0%, #f6290c 50%, #82bc40 50%, #47761d 100%);
}

.status.badge.paid {
  background: #82bc40;
  background: -moz-linear-gradient(top, #82bc40 0%, #47761d 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #82bc40), color-stop(100%, #47761d));
  background: -webkit-linear-gradient(top, #82bc40 0%, #47761d 100%);
  background: -o-linear-gradient(top, #82bc40 0%, #47761d 100%);
  background: -ms-linear-gradient(top, #82bc40 0%, #47761d 100%);
  background: linear-gradient(to bottom, #82bc40 0%, #47761d 100%);
}

.status.badge.dropped {
  background: #ff9800;
  background: -moz-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #ffb64a), color-stop(100%, #ff9800));
  background: -webkit-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
  background: -o-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
  background: -ms-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
  background: linear-gradient(to bottom, #ffb64a 0%, #ff9800 100%);
}

// status ends here

// button starts

.ant-table-cell .btn-group>.btn.glow.dropdown-toggle {
  height: 28px;
}

.btn-group,
.btn-group-vertical {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.btn-group>.btn.glow.dropdown-toggle {
  margin: 0 2px;
  padding: 6px 9px;
}

.btn-group>.btn.glow:hover {
  background: #fefefe;
  background: -moz-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background:
    -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0%, #fefefe),
      color-stop(100%, #f7f7f7));
  background: -webkit-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background: -o-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background: -ms-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background: linear-gradient(to bottom, #fefefe 0%, #f7f7f7 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#fefefe", endColorstr="#f7f7f7", GradientType=0);
}

.btn-group button.glow:hover {
  color: #a8b5c7;
}

.btn-group button.glow:active,
.btn-group button.glow.active {
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2) inset;
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2) inset;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2) inset;
  color: #a8b5c7;
}

.btn {
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.428571429;
  margin-bottom: 0;
  padding: 6px 12px;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.btn-group>.btn.glow {
  border: 1px solid #d0dde9;
  color: #313d4c;
  float: left;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  padding: 6px 14px;
}

.btn-group>.btn:first-child {
  margin-left: 0;
}

.btn-group button.glow {
  background: #fefefe;
  background: -moz-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background:
    -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0%, #fefefe),
      color-stop(100%, #f7f7f7));
  background: -webkit-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background: -o-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background: -ms-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background: linear-gradient(to bottom, #fefefe 0%, #f7f7f7 100%);
  border: 1px solid #d0dde9;
  color: #313d4c;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#fefefe", endColorstr="#f7f7f7", GradientType=0);
  float: left;
  font-family: "Open sans", Helvetica, Arial;
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
  padding: 5px 10px;
  -moz-transition: color 0.1s linear;
  -webkit-transition: color 0.1s linear;
  -o-transition: color 0.1s linear;
  transition: color 0.1s linear;
}

.caret {
  border-bottom: 0 dotted;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  width: 0;
}

.btn-glow {
  background: var(--white);
  background: -moz-linear-gradient(top, var(--white) 0%, #eef0f1 100%);
  background:
    -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0%, var(--white)),
      color-stop(100%, #eef0f1));
  background: -webkit-linear-gradient(top, var(--white) 0%, #eef0f1 100%);
  background: -o-linear-gradient(top, var(--white) 0%, #eef0f1 100%);
  background: -ms-linear-gradient(top, var(--white) 0%, #eef0f1 100%);
  background: linear-gradient(to bottom, var(--white) 0%, #eef0f1 100%);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  color: var(--dark-charcoal);
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  padding: 5px 10px;
  text-align: center;
  vertical-align: middle;
}

.btn-block {
  display: block;
  width: 100% !important;
}

.btn-glow.primary {
  i {
    line-height: 10px;
    margin-right: 5px;
  }
}

a.btn-glow.primary {
  color: var(--white);
}

.btn-glow.success {
  background: #96bf48;
  border: 1px solid #99bd56;
  color: var(--white);
}

.btn-glow.delete-field {
  background: linear-gradient(to bottom, #f85032 0%, #e73827 100%);
  background: -moz-linear-gradient(to bottom, #f85032 0%, #e73827 100%);
  background: -webkit-gradient(to bottom, #f85032 0%, #e73827 100%);
  background: -webkit-linear-gradient(to bottom, #f85032 0%, #e73827 100%);
  background: -o-linear-gradient(to bottom, #f85032 0%, #e73827 100%);
  background: -ms-linear-gradient(to bottom, #f85032 0%, #e73827 100%);
  border: 1px solid #e11117;
  color: var(--white);
}

// button ends

// page not found starts
.page-not-found {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 70vh;
  justify-content: center;
}

.page-not-found img {
  height: auto;
  margin-bottom: 18px;
  width: 130px;
}

.page-not-found h1 {
  font-size: 35px;
  font-weight: bold;
}

.page-not-found .btn-glow {
  font-size: 18px;
}

.page-not-found .btn-glow i {
  margin-right: 6px;
}

// page not found ends here

// loading content starts

.loading-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 70vh;
  justify-content: center;
}

.loading-content img {
  height: auto;
  margin-bottom: 20px;
  width: 100px;
}

.loading-content h1 {
  font-size: 22px;
  font-weight: 600;
}

// loading content ends

.align-right {
  text-align: right;
}

.top-filter-options {
  h2 {
    @include HeadingStyle;

    @media (max-width: 900px) {
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.content-container {
  padding: 0 14px;
}

.content-container .custom-background {
  padding: 0 !important;
}

// model for arrange column starts

.mobile-filter {
  display: none;
  left: 0;
  position: fixed;
  top: 40%;
  z-index: 999;

  @media (max-width: 900px) {
    display: block;
  }
}

.mobile-filter .btn-glow {
  padding: 7px 9px;
}

// add-new-invoice-button starts

.add-new-invoice-button {
  display: none;
  margin-top: 15px;
  width: 100%;

  @media (max-width: 900px) {
    display: block;
  }
}

// add-new-invoice-button ends

.ant-collapse>.ant-collapse-item {
  background: transparent !important;
  background:
    -webkit-gradient(left top,
      left bottom,
      color-stop(0%, var(--white)),
      color-stop(47%, #f6f6f6),
      color-stop(100%, #ededed)) !important;
  background: linear-gradient(to bottom, var(--white) 0%, #f6f6f6 47%, #ededed 100%) !important;
  border-bottom: 1px solid #c6d2d9;
}

// add-proj-timesheet starts

.add-proj-timesheet {
  width: 100%;

  .form-fields {
    margin-bottom: 10px;

    label {
      display: inline-block;
      font-weight: 600;
      margin-bottom: 6px;
    }
  }

  .add-proj {
    color: var(--primary);
    cursor: pointer;
    float: right;
    font-size: 15px;
    font-weight: bold;
    text-align: right;

    span {
      margin-right: 4px;
    }
  }

  #totalHours {
    // width: 110px;
    color: var(--primary);
    font-size: 20px;
    font-weight: bold;
    padding: 22px 20px;
  }
}

// form fields

.form-fields,
.ant-modal-content .form-field {
  margin-bottom: 13px;
}

.form-fields label,
.ant-modal-content .form-field label {
  font-weight: bold;
}

.ant-radio-group label {
  font-weight: normal;
}

.ant-modal-content .form-field label {
  font-size: 12px;
  margin-bottom: 3px;
}

// stop timer

.stop-timer {
  background: #d3d3d3;
  border-radius: 5px;
  color: var(--primary);
  cursor: move;
  display: none;
  padding: 0 10px;
  position: fixed;
  right: 0;
  text-align: center;
  top: 57px;
  width: fit-content;
  z-index: 9;

  .stop-icon {
    img {
      cursor: pointer;
      height: 27px;
      margin-left: 8px;
      margin-top: 0;
      width: auto;
    }
  }

  #time-count {
    font-size: 20px;
    font-weight: bold;
  }
}

.ant-tabs-nav .ant-tabs-tab-btn {
  font-size: 15px;
}

.ant-tabs-nav .ant-tabs-ink-bar {
  background: var(--secondary);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--secondary);
}

.ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: var(--primary);
}

.top-title-section {
  margin-bottom: 7px;
  margin-top: 23px;
  width: 100%;

  h2 {
    color: var(--primary);
    font-size: 19px;
    font-weight: 600;
  }
}

// ant steps

.ant-steps-navigation .ant-steps-item::before,
.ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--secondary);
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: var(--secondary);
}

// profile name

.profile-name {
  background-color: var(--secondary);
  color: var(--white);
  text-align: center;

  span {
    font-weight: bold;
  }
}

// sider
#mobile-sider-menu {
  @media (max-width: 900px) {
    display: none;
    position: absolute;
    z-index: 2;
  }
}

.required {
  color: #f44336;
  margin-left: 4px;
}

.ant-input[disabled],
.ant-picker-input>input[disabled] {
  color: #262626;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

.add-proj-timesheet {
  .submitted {
    color: var(--primary);
  }

  .approved {
    color: var(--success);
  }
}

.upload-invoice-expense-note {
  background-color: var(--white);
  border: 1px solid #efefef;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 #e4e4e4;
  font-size: 13px;
  line-height: 184%;
  margin-bottom: 30px;
  padding: 20px 24px;
  position: relative;
  width: 100%;

  .heading {
    border-bottom: 1px solid #f1f2f3;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-bottom: 24px;
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -20px;
    padding: 10px 20px;
    position: relative;

    h2 {
      color: var(--black);
      font-size: 17px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
}

.ant-upload {
  width: 100% !important;
}

.fixed-action-area {
  text-align: center;

  button {
    margin: 0 5px;
  }
}

// upload field starts

.file-upload-field {
  background: #fbfbfb;
  border: 1px dashed #b3b3b3;
  border-radius: 5px;
  color: var(--black);
  cursor: pointer;
  padding: 20px;
  text-align: center;

  i {
    color: var(--secondary);
  }
}

// upload field ends

.list-field {
  border-radius: 10px;
  border-top: 1px solid #ededed;
  box-shadow: 5px 5px 20px #e8e8e8;
  display: flex;
  margin-bottom: 10px;
  margin-top: 5px;
  padding: 15px;
  padding-bottom: 8px;
  position: relative;

  >.ant-row {
    flex: 1;
  }

  .remove-column {
    .btn-glow.delete-field {
      padding: 0 4px;
    }
  }
}

// .list-field:first-child {
//   border-top: 0;
//   margin-bottom: 0;
//   padding-top: 0;
//   position: relative;
// }

// save order table starts
.save-order-table {
  th {
    white-space: nowrap;
  }

  tr {

    td,
    th {
      padding: 0.75rem 0.3rem;
      vertical-align: middle;
    }
  }
}

.custom-loader-back-active {
  height: 100vh !important;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2147483647;
}

.custom-loader {
  // position: absolute;
  // top: 45px !important;
  top: 53px !important;
}

.table.borderless {

  td,
  th {
    border: 0;
  }
}

.ant-btn-success {
  background: #96bf48;
  border: 1px solid #99bd56;
  color: var(--white);

  &:active,
  &:hover,
  &:focus {
    background: darken($color: #99bd56, $amount: 8);
    border-color: darken($color: #99bd86, $amount: 8);
    color: var(--white);
  }
}

// design-holder starts

.designer-section {
  background: var(--white);
  margin: 15px auto;
  width: fit-content;

  .design-holder {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3;
  }
}

.header-text {
  color: var(--primary);
  font-weight: 600;
}

// social login starts

.social-login {
  margin-top: 20px;
  text-align: center;

  button {
    border: 0;
    border-radius: 3px;
    box-shadow: 0 2px 4px #d2d2d2;
    height: 37px;
    margin-right: 20px;
    width: 40%;

    img {
      height: 25px;
      margin-right: 6px;
      width: 25px;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

// ant-popover-inner-content action buttons
.ant-popover,
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  font-size: 15px;
}

.ant-popover-inner-content .action-buttons {
  min-width: 100px;

  ul {
    list-style: none;
    margin-bottom: 0;
    padding: 0;

    li {
      cursor: pointer;
      display: block;

      a {
        border-bottom: 1px solid var(--light-gray);
        color: #444;
        display: block;
        padding: 5px 0;
        text-align: left;

        i {
          margin-right: 6px;
          position: relative;
          top: 2px;
        }
      }
    }

    &:last-child {
      a {
        border-bottom: 0;
      }
    }
  }
}

.ant-popover {
  z-index: 1500;
}

.company-name {
  font-size: 17px;
  font-weight: bold;
  text-align: right;

  span {
    color: var(--light-blue);
    font-weight: normal;
  }
}

.wizard-section {
  @include card-bg;

  bottom: 0;
  display: flex;
  left: 0;
  padding: 10px 20px;
  position: fixed;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  z-index: 999;

  .ant-steps:not(.ant-steps-dot):not(.ant-steps-navigation):not(.ant-steps-vertical) .ant-steps-item {
    padding-bottom: 4px;
  }

  .wizard-steps {
    padding-right: 20px;
    width: 60%;

    .ant-steps-item-title {
      font-size: 14px;
    }
  }

  .action-items {
    border-left: 1px solid var(--light-gray);
    padding-left: 20px;
    width: 40%;

    button {
      margin-right: 10px;
    }
  }
}

.ant-layout-sider-children {
  background: #fbfbfb;
}

.ant-checkbox-wrapper.middle .ant-checkbox {
  top: 0;
}

.same-as-shipping-container {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: -29px;

  i {
    color: #a3a3a3;
  }
}

.field-array {
  display: flex;

  >.ant-space-item {
    flex: 1;

    .ant-skeleton-element {
      display: block;
      flex: 1;

      .ant-skeleton-input {
        flex: 1;
      }
    }
  }
}

.terms-condition-content {
  @extend #{".text-truncate"};

  max-width: 60ch;

  @media (max-width: 1024px) {
    max-width: 40ch;
  }

  @media (max-width: 768px) {
    max-width: 30ch;
  }
}

ul {
  &.filter-buttons {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;

      a {
        color: var(--dark-charcoal);
        padding: 5px 10px;

        i {
          color: var(--caribbean-green);
          margin-right: 8px;
        }
      }
    }
  }
}

.ant-modal-content {
  .ant-modal-title {
    font-size: 16px;
    font-weight: 600;
  }
}

.ant-modal-body {
  .links {
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 #e0e0e0;
    padding: 20px;
    text-align: center;

    img {
      height: 45px;
      margin-bottom: 10px;
      width: auto;
    }
  }

  .links .links h4 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0;
  }
}

.top-filter-options {
  margin-bottom: 7px;
  margin-top: 23px;
  padding: 0 15px;
  width: 100%;

  h2 {
    font-size: 19px;
  }

  .exports-and-settings {
    display: none;
    width: 100%;

    ul {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: right;

      li {
        display: inline-block;
        padding: 0 6px;

        a {
          display: block;
        }
      }
    }
  }

  button {
    height: 31px;
    padding: 2px 12px;
  }
}

.app-sidebar {
  min-height: 86vh;
}

.arrange-column {
  .action-item {
    margin-bottom: 20px;
    width: 100%;
  }
}

.income-container {
  padding: 0 14px;

  .custom-background {
    padding: 0 !important;
  }
}

.acc-range {
  align-self: center;
}

.invoice-section {
  background-color: var(--white);
  margin-bottom: 25px;
  position: relative;
  width: 100%;

  h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 6px;
  }

  h3 {
    font-size: 17px;
    margin-bottom: 0;
  }

  &.border-bottom {
    border-bottom: 1px sold #eee;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .form-field {
    position: relative;

    label {
      color: var(--black);
      display: inline-block;
      font-size: 11.5px;
      font-weight: 500;
      line-height: 21px;
      margin-bottom: 5px;
      text-transform: capitalize;

      &:not(.ant-checkbox-wrapper) {
        width: 100%;
      }
    }
  }

  label:not(.ant-checkbox-wrapper) span {
    color: #f44336;
    margin-left: 4px;
  }

  .buyer-seller-details {
    h4 {
      font-weight: 600;
    }

    div {
      margin-bottom: 5px;
      text-transform: capitalize;
    }

    div:last-child {
      margin-bottom: 0;
    }
  }
}

.save-changes {
  display: block;
  text-align: center;
  width: 100%;

  button {
    margin: 0 10px;
  }
}

.line-item-footer {
  display: flex;
  float: right;
}

.line-item-footer-content {
  padding: 5px;
}

.invoice-attachment-container {
  padding: 25px 25px 25px 0;
}

.site-calendar-demo-card {
  border: 1px solid #261d1d;
  border-color: #026ec1;
  border-radius: 6px;
  margin: 11px;
  width: 300px;
}

.action-content {
  ul {
    list-style: none;
    margin-bottom: 0;
    padding: 0;

    li {
      border-bottom: 1px solid #f3f3f3;
      cursor: pointer;
      display: block;
      padding: 7px 0;

      a {
        border-bottom: 1px solid var(--light-gray);
        color: #444;
        display: block;
        padding: 5px 0;
        text-align: left;

        i {
          margin-right: 6px;
          position: relative;
          top: 2px;
        }
      }
    }

    &:last-child {
      a {
        border-bottom: 0;
      }
    }
  }
}

.full-section {
  background-size: cover;
  min-height: 80vh;
  // padding: 15vh 0 5vh;
  position: relative;
  width: 100%;

  h1.login-text {
    color: var(--white);
    font-size: 30px;
    font-weight: 300;
    padding: 91px 75px 97px 35px;
    position: relative;

    &::before {
      content: "";
      height: 51%;
      left: 0;
      position: absolute;
      top: 24%;
      width: 100%;
      width: 8px;

      @media (max-width: 1100px) {
        height: 60%;
      }

      @media (max-width: 900px) {
        height: 55%;
        top: 24%;
      }

      @media (max-width: 600px) {
        height: 70%;
        top: 20%;
      }
    }

    @media (max-width: 1100px) {
      font-size: 36px;
      padding: 30px 20px 10px 30px;
    }

    @media (max-width: 900px) {
      font-size: 30px;
    }

    @media (max-width: 600px) {
      font-size: 25px;
    }

    @media (max-width: 400px) {
      font-size: 20px;
    }
  }

  .container-fluid {
    display: table-cell;
    vertical-align: middle;
  }
}

.light-background {
  // background: #ffffffde;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  padding: 40px;
  position: relative;

  .ant-input-prefix {
    color: #444;
    font-size: 17px;
    margin-right: 7px;
  }

  img {
    height: 25px;
  }

  img.logo {
    height: 30px;
    margin-bottom: 20px;
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    color: var(--display-text-color);

    // font-size: 26px;
    // font-weight: 500;
  }

  h3 {
    font-weight: 500;
  }

  .form-fields {
    margin-bottom: 17px;

    button {
      height: 40px;
      width: 100%;
    }

    &:last-child {
      text-align: center;
    }
  }

  .forgot-passsword {
    font-size: 15px;
    text-align: center;
  }

  .fields-holder {
    margin: 0 auto;
    width: 92%;
    z-index: 1;
    position: relative;
    padding-top: 18vh !important;

    h1 {
      color: var(--primary);
    }

    .ant-input-affix-wrapper {
      height: 40px;
    }

    @media (max-width: 767px) {
      width: 80%;
    }

    @media (max-width: 500px) {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    padding: 30px;
  }
}

.card-background {
  .card-label {
    border-bottom: 1px solid #d3d3d3;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .left-section {
    background: #ffffffde;

    // background-color: var(--white);
    border-radius: 7px;
    margin: 30px !important;
    padding: 20px !important;
  }

  .right-section {
    margin: 20px !important;
    padding: 20px !important;
    text-align: center;

    i {
      font-size: 100px;
      margin: 20px;
    }
  }
}

.card-tile {
  @include card-bg;

  border-radius: 7px;
  color: #fff;
  padding: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

h1.title {
  font-size: 1.9rem;
  text-decoration: underline;
  text-decoration-color: var(--white);
  text-underline-offset: 10px;

  span {
    color: var(--primary-alt);
  }
}

@media (max-width: 1920px) {
  h1.title {
    font-size: 1.5rem;
  }
}

.video-modal {
  .ant-modal-body {
    background-color: var(--primary);
  }

  .anticon svg {
    fill: var(--primary-alt);
  }
}

.minHeight {
  min-height: 100vh;
}

.home-page {
  .minHeight {
    min-height: auto;
  }
}

.flip {
  transform: scaleX(-1);
}

// .ant-tooltip-inner {
//   background-color: var(--secondary-alt) !important;
//   color: var(--light-blue);
// }

// .ant-tooltip-arrow-content {
// --antd-arrow-background-color: var(--secondary-alt) !important;
// }

.label-tooltip {
  color: var(--secondary);
  cursor: pointer;
  padding-left: 3px;
}

.left-section.mt-12 {
  margin-top: 12vh !important;
}

.show-hide-details {
  span {
    background-color: var(--primary);
    border-radius: 20px;
    color: var(--white);
    padding: 3px 10px;
  }
}

.ant-table-cell {

  .ant-btn-primary,
  .ant-btn-secondary {
    border-radius: 5px;
  }
}

.ant-btn-default {
  background-color: var(--portal-primary);
  border-radius: 20px;
  color: var(--white);

  &:hover,
  &:focus,
  &:active {
    background-color: var(--primary);
    color: var(--white);
  }
}

.ant-btn:hover {
  border-color: transparent;
}

.ant-btn:hover {
  border-color: transparent;
}

.secondary-bold-text {
  color: var(--secondary);
  font-size: 18px;
  font-weight: 600;
}

h1.header-text {
  font-family: var(--oswaldFont);
  color: var(--black);
  margin: 10px 0;
  font-size: 1.5rem;

  span {
    color: var(--primary);
  }
}

h1.sub-header-text {
  font-family: var(--oswaldFont);
  color: var(--dark-gray);
  margin: 10px 0;
  font-size: 1rem;
}

.white-text {
  color: var(--white) !important;
}

.upper-case {
  text-transform: uppercase;
}

.mt-22 {
  margin-top: 22vh !important;
}

.ant-table,
.ant-collapse {
  font-size: 1rem;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-select-item,
.ant-select,
.ant-picker-dropdown,
input[type="text"],
input[type="password"],
input[type="number"],
input[type="date"],
.ant-picker,
select {
  font-size: 1rem;
}


.messenger-wrap {
  .right-wrap {
    .custom-table {
      .tiles {
        .bubble-right {
          padding: 10px 15px;
          border-radius: 10px;
          box-shadow: 0px 0px 5px #ccc;
          background: var(--alice-blue);
          margin-top: 2vh;

          &:after {
            content: ' ';
            position: absolute;
            width: 0;
            height: 0;
            left: auto;
            right: 38px;
            bottom: -20px;
            border: 12px solid;
            border-color: var(--alice-blue) var(--alice-blue) transparent transparent;
          }
        }

        .bubble-left {
          padding: 10px 15px;
          border-radius: 10px;
          box-shadow: 0px 0px 5px #ccc;
          background: var(--alice-light-blue);
          margin-top: 2vh;

          &:after {
            content: ' ';
            position: absolute;
            width: 0;
            height: 0;
            left: 38px;
            right: auto;
            top: auto;
            bottom: -20px;
            border: 12px solid;
            border-color: var(--alice-light-blue) transparent transparent var(--alice-light-blue);
          }
        }
      }
    }
  }

  .left-wrap {
    .custom-table {
      .tiles {
        padding: 10px 15px;
        border-radius: 3px;
        box-shadow: 0px 0px 5px #ccc;
        background: var(--very-light-gray);
        margin-bottom: 1vh;
      }
    }
  }
}